.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 1px 5px 13px 3px #33333320;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 5%;
  padding-left: 5%;
}

.logo-img {
  width: 30%;
}
.menu {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.menu-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  width: 100%;
  justify-content: space-around;
  font-size: 20px;
}

.menu-item {
  padding: 5%;
  border-radius: 7px;
  transition: 500ms;
}

.menu-item:hover {
  box-shadow: 5px 10px 20px #f2f0eb inset;
  color: #ff8c64
}

.menu-item-active {
  color: #ff8c64
}

.link-item:hover {
  color: #ff8c64
}

.link-item {
  text-decoration: none;
  color: black;
  transition: 500ms;
}
.main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.media_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px;

}

.box_img {
  background-color: transparent;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  background-position: center;
  background-image: url("./styles/depositphotos_58757733-stock-illustration-vector-pattern-with-dresses-on-01.png");
  background-size: cover;
  border-radius: 10px;
  width: 80vw;
  height: 450px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 4%;

  overflow-x: auto;
  padding: 0 2%;
}

.box_img_media {
  background-color: transparent;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  background-position: center;
  background-image: url("./styles/depositphotos_58757733-stock-illustration-vector-pattern-with-dresses-on-01.png");
  background-size: cover;
  border-radius: 10px;
  width: 80vw;
  height: 400px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 4%;
  flex-direction: column;
  overflow-x: auto;
  padding: 0 2%;
}

.input {
  margin-top: 5%;
  padding: 0px 10px;
  width: 100%;
  font-size: 10px;
}

.single_img {
  width: 65%;
  height: auto;
  border-radius: 10px;
}

.single_imgcat {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 5%;
}

.upload_img_container {
  display: flex;
  flex-direction: column;
}

.upload_button {
  margin-top: 10px;
  border: none;
  background-color: black;
  border-radius: 4px;
  cursor: pointer;
  height: 35px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  transition: 500ms;
}

.delete_img {
  width: 20px;
  height: 20px;
}

.icons-edit-delete {
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete_btn {
  position: relative;
  display: flex;
  justify-content: end;
  width: 90%;
  cursor: pointer;
}

.fa-icon {
  width: 18px;
  height: 18px;
}

 #edit-icon {
   margin-top: 10%;
   width: 21px;
   height: 21px;
   transition: 500ms;
   cursor: pointer;
 }

 #edit-icon:hover {
   color: #4978ff;
 }

.images_array {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 0 20px;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  width: 23%;
}

.images_arraycat {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 0 20px;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  padding: 5px 5px;
  width: 18%;
}

.images_array_media {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  width: 60vw;
  padding: 10px 0;
}

.btn-delete {
  background-color: transparent;
  border: none;
  color: black;
  transition: 200ms;
  cursor: pointer;
}

.btn-delete:hover {
  color: #c62121;
}

.container-select {
  width: 80vw;
  display: flex;
}

#multiple-select {
  width: 100%;
}
#multiple-select-modal {
  margin-bottom: 10px;
}

.upload_button_select {
  border: none;
  background-color: black;
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 15px;
  transition: 500ms;
  color: white;
  margin-left: 10px;
  width: 10%;
}

.upload_button_select:hover,
.upload_button:hover {
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);
}

.container-select-input {
  display: flex;
  flex-direction: column;
}

.container-select-save {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.label-category {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 90%;
  align-items: baseline;
  margin-bottom: 10px;
}

.save-btn {
  font-size: 18px;
  border: none;
  background-color: #3eb595;
  font-weight: bold;
  color: white;
  width: 8%;
  border-radius: 4px;
  cursor: pointer;
  transition: 500ms;
}

.save-btn:hover {
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.4);
}

.category-label {
  margin-bottom: 0px;
  font-size: 15px;
}

.modal-content {
  padding: 0 5%;
}