.login-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
    padding: 25px 30px;
}

.input-login {
    margin-bottom: 15px;
}

.label-login {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}

.img-login {
    width: 150px;
}

.button-login {
    margin-top: 10px;
    border: none;
    background-color: black;
    border-radius: 4px;
    cursor: pointer;
    height: 35px;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 15px;
    color: white;
    transition: 500ms;
}